import "../../i18n";
import { useTranslation } from "react-i18next";
import { ConstructionPageData } from "../../Data/Translation/ConstructionPage/Data_ConstructionPage";
import "./ConstructionPage.scss"; // import CSS file
import { useInView } from "react-intersection-observer";
import { Variants, motion, useAnimation } from "framer-motion";
import { useEffect } from "react";
import image from '../../Assets/images/Pages/construction-image.jpg';
import SingleLayerIntroSection from "../../Components/Text/ParallaxTitle/Simple/SimpleParallaxTitle";
import '../../Styles/Shared/utilities.scss';
import useDetectView from "../../Components/Hook/useDetectView";
import { View } from "../../Data/Constants";

interface InViewMotionDivProps {
    children: React.ReactNode;
    variants: Variants;
    className?: string;
}

const leftSideVariants: Variants = {
    hidden: { opacity: 0, x: -50 },
    visible: { opacity: 1, x: 0, transition: { duration: 1, ease: 'easeInOut' } }
};

const rightSideVariants: Variants = {
    hidden: { opacity: 0, x: 50 },
    visible: { opacity: 1, x: 0, transition: { duration: 1, ease: 'easeInOut' } }
};

const textZoomFadeVariants: Variants = {
    hidden: { opacity: 0, scale: 0.8 },
    visible: { opacity: 1, scale: 1, transition: { duration: 0.8, ease: 'easeOut' } }
};

const staggerContainer = {
    hidden: { opacity: 0 },
    visible: {
        opacity: 1,
        transition: {
            staggerChildren: 0.2
        }
    }
};

export default function Construction() {
    const { t, i18n } = useTranslation();
    const currentPageData = i18n.language === 'fr' ? ConstructionPageData.constructionPage_fr : ConstructionPageData.constructionPage_en;
    const currentView = useDetectView();

    return (
        <div>
            <div>
            <SingleLayerIntroSection 
                    title={t("construction.title.title")} 
                    backgroundImage={image} 
                    subtitle="" 
                    textBackground={{enabled: true, color: "red", rounded: true, opacity: 0.5}}/>
            </div>
            
            <div style={{margin: "2.5rem 0 5rem 0"}}>
                <motion.div initial="hidden" animate="visible" variants={staggerContainer}>
                    
                    {
                        // ITEM 1
                    }
                    <motion.div 
                    transition={{ type: "spring", stiffness: 400, damping: 17, duration: 3 }}
                    whileHover={currentView !== View.Mobile ? { scale: 1.05 } : {}}
                    whileTap={currentView !== View.Mobile ? { scale: 0.9 } : {}}>
                        <motion.div
                            className="construction-card-container"
                            whileInView={{ backgroundColor: currentView!==View.Mobile ? ['#ffffff', '#f3f3f3', '#101a2c'] : ['#ffffff', '#ffffff', '#ffffff'], color: currentView!==View.Mobile ? ['#101a2c', '#677896', '#ffffff'] : ['#ffffff', '#677896', '#101a2c']}}
                            transition={{ duration: 1 }}
                            style={{boxShadow: currentView!==View.Mobile ? "0 4px 12px rgba(0, 0, 0, 0.1)" : "0 0 0 0"}}
                        >
                            <div className="dual-content-section">
                                <InViewMotionDiv variants={leftSideVariants} className="left-side">
                                    <motion.h3 variants={textZoomFadeVariants}>
                                        {t("construction.phases.pre_dev.title")}
                                    </motion.h3>
                                    <motion.p className="justify-text" variants={textZoomFadeVariants}>
                                        {t("construction.phases.pre_dev.text")}
                                    </motion.p>
                                </InViewMotionDiv>
                                <InViewMotionDiv variants={rightSideVariants} className="right-side">
                                    <ul className="services-list">
                                        {currentPageData.phases.pre_dev.services.map(service => (
                                            currentView!==View.Mobile ?
                                            <motion.li 
                                                key={service} 
                                                className="services-list-item" 
                                                whileHover={{ color: "#101a2c", backgroundColor: "#f0f0f0", fontWeight: "bold", cursor: "default"}}
                                                initial={{ opacity: 0, y: 20 }}
                                                animate={{ opacity: 1, y: 0 }}
                                                transition={{ duration: 0.5 }}
                                            >
                                                {service}
                                            </motion.li>
                                            :
                                            <motion.li 
                                                key={service} 
                                                className="services-list-item" 
                                                initial={{ opacity: 0, y: 20 }}
                                                animate={{ opacity: 1, y: 0 }}
                                                transition={{ duration: 0.5 }}
                                            >
                                                {service}
                                            </motion.li>
                                        ))}
                                    </ul>
                                </InViewMotionDiv>
                            </div>
                        </motion.div>
                    </motion.div>

                    {
                        // ITEM 2
                    }
                    <motion.div 
                    transition={{ type: "spring", stiffness: 400, damping: 17, duration: 3 }}
                    whileHover={currentView !== View.Mobile ? { scale: 1.05 } : {}}
                    whileTap={currentView !== View.Mobile ? { scale: 0.9 } : {}}>
                        <motion.div
                            className="construction-card-container"
                            // OLD CODE -> whileInView={{ backgroundColor: ['#ffffff', '#f8f9fa', '#101a2c'], color: ['#101a2c', '#677896', '#ffffff']}}
                            whileInView={{ backgroundColor: ['#ffffff', '#f3f3f3', '#101a2c'], color: ['#101a2c', '#677896', '#ffffff']}}
                            transition={{ duration: 1 }}
                            style={{boxShadow: currentView!==View.Mobile ? "0 4px 12px rgba(0, 0, 0, 0.1)" : "0 0 0 0", padding: currentView!==View.Mobile ? "0 0 0 0" : "40px 0"}}
                        >
                            <div className="dual-content-section">
                                <InViewMotionDiv variants={leftSideVariants} className="left-side">
                                    <motion.h3 variants={textZoomFadeVariants}>
                                        {t("construction.phases.conception.title")}
                                    </motion.h3>
                                    <motion.p className="justify-text" variants={textZoomFadeVariants}>
                                        {t("construction.phases.conception.text")}
                                    </motion.p>
                                </InViewMotionDiv>
                                <InViewMotionDiv variants={rightSideVariants} className="right-side">
                                    <ul className="services-list">
                                        {currentPageData.phases.conception.services.map(service => (
                                            currentView!==View.Mobile ?
                                            <motion.li 
                                                key={service} 
                                                className="services-list-item" 
                                                whileHover={{ color: "#101a2c", backgroundColor: "#f0f0f0", fontWeight: "bold", cursor: "default"}}
                                                initial={{ opacity: 0, y: 20 }}
                                                animate={{ opacity: 1, y: 0 }}
                                                transition={{ duration: 0.5 }}
                                            >
                                                {service}
                                            </motion.li>
                                            :
                                            <motion.li 
                                                key={service} 
                                                className="services-list-item" 
                                                initial={{ opacity: 0, y: 20 }}
                                                animate={{ opacity: 1, y: 0 }}
                                                transition={{ duration: 0.5 }}
                                            >
                                                {service}
                                            </motion.li>
                                        ))}
                                    </ul>
                                </InViewMotionDiv>
                            </div>
                        </motion.div>
                    </motion.div>

                    {
                        // ITEM 3
                    }
                    <motion.div 
                    transition={{ type: "spring", stiffness: 400, damping: 17, duration: 3 }}
                    whileHover={currentView !== View.Mobile ? { scale: 1.05 } : {}}
                    whileTap={currentView !== View.Mobile ? { scale: 0.9 } : {}}>
                        <motion.div
                            className="construction-card-container"
                            whileInView={{ backgroundColor: currentView!==View.Mobile ? ['#ffffff', '#f3f3f3', '#101a2c'] : ['#ffffff', '#ffffff', '#ffffff'], color: currentView!==View.Mobile ? ['#101a2c', '#677896', '#ffffff'] : ['#ffffff', '#677896', '#101a2c']}}
                            transition={{ duration: 1 }}
                            style={{boxShadow: currentView!==View.Mobile ? "0 4px 12px rgba(0, 0, 0, 0.1)" : "0 0 0 0"}}
                        >
                            <div className="dual-content-section">
                                <InViewMotionDiv variants={leftSideVariants} className="left-side">
                                    <motion.h3 variants={textZoomFadeVariants}>
                                        {t("construction.phases.management.title")}
                                    </motion.h3>
                                    <motion.p className="justify-text" variants={textZoomFadeVariants}>
                                        {t("construction.phases.management.text")}
                                    </motion.p>
                                </InViewMotionDiv>
                                <InViewMotionDiv variants={rightSideVariants} className="right-side">
                                    <ul className="services-list">
                                        {currentPageData.phases.management.services.map(service => (
                                            currentView!==View.Mobile ?
                                            <motion.li 
                                                key={service} 
                                                className="services-list-item" 
                                                whileHover={{ color: "#101a2c", backgroundColor: "#f0f0f0", fontWeight: "bold", cursor: "default"}}
                                                initial={{ opacity: 0, y: 20 }}
                                                animate={{ opacity: 1, y: 0 }}
                                                transition={{ duration: 0.5 }}
                                            >
                                                {service}
                                            </motion.li>
                                            :
                                            <motion.li 
                                                key={service} 
                                                className="services-list-item" 
                                                initial={{ opacity: 0, y: 20 }}
                                                animate={{ opacity: 1, y: 0 }}
                                                transition={{ duration: 0.5 }}
                                            >
                                                {service}
                                            </motion.li>
                                        ))}
                                    </ul>
                                </InViewMotionDiv>
                            </div>
                        </motion.div>
                    </motion.div>
                    
                    {
                        // ITEM 4
                    }
                    <motion.div 
                    transition={{ type: "spring", stiffness: 400, damping: 17, duration: 3 }}
                    whileHover={currentView !== View.Mobile ? { scale: 1.05 } : {}}
                    whileTap={currentView !== View.Mobile ? { scale: 0.9 } : {}}>
                        <motion.div
                            className="construction-card-container"
                            whileInView={{ backgroundColor: ['#ffffff', '#f3f3f3', '#101a2c'], color: ['#101a2c', '#677896', '#ffffff']}}
                            transition={{ duration: 1 }}
                            style={{boxShadow: currentView!==View.Mobile ? "0 4px 12px rgba(0, 0, 0, 0.1)" : "0 0 0 0", padding: currentView!==View.Mobile ? "0 0 0 0" : "40px 0"}}
                        >
                            <div className="dual-content-section">
                                <InViewMotionDiv variants={leftSideVariants} className="left-side">
                                    <motion.h3 variants={textZoomFadeVariants}>
                                        {t("construction.phases.construction.title")}
                                    </motion.h3>
                                    <motion.p className="justify-text" variants={textZoomFadeVariants}>
                                        {t("construction.phases.construction.text")}
                                    </motion.p>
                                </InViewMotionDiv>
                                <InViewMotionDiv variants={rightSideVariants} className="right-side">
                                    <ul className="services-list">
                                        {currentPageData.phases.construction.services.map(service => (
                                            currentView!==View.Mobile ?
                                            <motion.li 
                                                key={service} 
                                                className="services-list-item" 
                                                whileHover={{ color: "#101a2c", backgroundColor: "#f0f0f0", fontWeight: "bold", cursor: "default"}}
                                                initial={{ opacity: 0, y: 20 }}
                                                animate={{ opacity: 1, y: 0 }}
                                                transition={{ duration: 0.5 }}
                                            >
                                                {service}
                                            </motion.li>
                                            :
                                            <motion.li 
                                                key={service} 
                                                className="services-list-item" 
                                                initial={{ opacity: 0, y: 20 }}
                                                animate={{ opacity: 1, y: 0 }}
                                                transition={{ duration: 0.5 }}
                                            >
                                                {service}
                                            </motion.li>
                                        ))}
                                    </ul>
                                </InViewMotionDiv>
                            </div>
                        </motion.div>
                    </motion.div>

                    {
                        // ITEM 5
                    }
                    <motion.div 
                    transition={{ type: "spring", stiffness: 400, damping: 17, duration: 3 }}
                    whileHover={currentView !== View.Mobile ? { scale: 1.05 } : {}}
                    whileTap={currentView !== View.Mobile ? { scale: 0.9 } : {}}>
                        <motion.div
                            className="construction-card-container"
                            whileInView={{ backgroundColor: currentView!==View.Mobile ? ['#ffffff', '#f3f3f3', '#101a2c'] : ['#ffffff', '#ffffff', '#ffffff'], color: currentView!==View.Mobile ? ['#101a2c', '#677896', '#ffffff'] : ['#ffffff', '#677896', '#101a2c']}}
                            transition={{ duration: 1 }}
                            style={{boxShadow: currentView!==View.Mobile ? "0 4px 12px rgba(0, 0, 0, 0.1)" : "0 0 0 0"}}
                        >
                            <div className="dual-content-section">
                                <InViewMotionDiv variants={leftSideVariants} className="left-side">
                                    <motion.h3 variants={textZoomFadeVariants}>
                                        {t("construction.phases.post.title")}
                                    </motion.h3>
                                    <motion.p className="justify-text" variants={textZoomFadeVariants}>
                                        {t("construction.phases.post.text")}
                                    </motion.p>
                                </InViewMotionDiv>
                                <InViewMotionDiv variants={rightSideVariants} className="right-side">
                                    <ul className="services-list">
                                        {currentPageData.phases.post.services.map(service => (
                                            currentView!==View.Mobile ?
                                            <motion.li 
                                                key={service} 
                                                className="services-list-item" 
                                                whileHover={{ color: "#101a2c", backgroundColor: "#f0f0f0", fontWeight: "bold", cursor: "default"}}
                                                initial={{ opacity: 0, y: 20 }}
                                                animate={{ opacity: 1, y: 0 }}
                                                transition={{ duration: 0.5 }}
                                            >
                                                {service}
                                            </motion.li>
                                            :
                                            <motion.li 
                                                key={service} 
                                                className="services-list-item" 
                                                initial={{ opacity: 0, y: 20 }}
                                                animate={{ opacity: 1, y: 0 }}
                                                transition={{ duration: 0.5 }}
                                            >
                                                {service}
                                            </motion.li>
                                        ))}
                                    </ul>
                                </InViewMotionDiv>
                            </div>
                        </motion.div>
                    </motion.div>
                </motion.div>
            </div>
        </div>
    );
}

const InViewMotionDiv: React.FC<InViewMotionDivProps> = ({ children, variants, className }) => {
    const [ref, inView] = useInView({ triggerOnce: true });

    return (
        <motion.div
            ref={ref}
            variants={variants}
            initial="hidden"
            animate={inView ? "visible" : "hidden"}
            className={className}
        >
            {children}
        </motion.div>
    );
};
