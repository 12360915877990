import React from 'react';
import './AboutPage.scss';
import { HAngledBackgroundTextSection } from "../../Components/Text/AngledBackgroundTextSection/AngledBackgroundTextSection";
import { useTranslation } from "react-i18next";
import { CompanyValues } from "../../Data/Translation/HistoryPage/Values";
import { motion } from "framer-motion";
import i18n from "../../i18n";
import ACQLogo from '../../Assets/images/Logo/ACQ-2.jpg';
import IELogo from '../../Assets/images/Logo/logo-info-excavation.png';
import CCEMLogo from '../../Assets/images/Logo/logo-chambre-commerce-laureat-2015.png';
import GPSSTLogo from '../../Assets/images/Logo/gpsst-logo.jpg';
import SingleLayerIntroSection from '../../Components/Text/ParallaxTitle/Simple/SimpleParallaxTitle';
import IntroImage from '../../Assets/images/Company/Team/fondateurs.jpg'
import useDetectView from '../../Components/Hook/useDetectView';
import { View } from '../../Data/Constants';

interface Award {
  name: string;
  logo: string; // Assuming the logo is a string URL. Adjust if it's different.
  year: number;
  description: string;
}

interface AwardsDataStructure {
  [key: string]: { [key: string]: Award }; // Add this line
  en: { [key: string]: Award };
  fr: { [key: string]: Award };
}

const AwardsData: AwardsDataStructure = {
    en:{
      ACQ2014:{
        "name": "ASSOCIATION DE LA CONSTRUCTION DU QUÉBEC",
        "logo": ACQLogo,
        "year": 2014,
        "description": "Prevention mutual trophy"
      },
      CCEM:{
        "name": "CHAMBRE DE COMMERCE DE L'EST DE MONTRÉAL",
        "logo": CCEMLogo,
        "year": 2015,
        "description": "\"ESTim\" award winner - Construction company"
      },
      GPSST:{
          "name": "LES GRANDS PRIX SANTÉ ET SÉCURITÉ DU TRAVAIL",
          "logo": GPSSTLogo,
          "year": 2015,
          "description": "Regional finalist - Innovation - Large companies"
      },
      IE:{
        "name": "INFO-EXCAVATION",
        "logo": IELogo,
        "year": 2016,
        "description": "Damage prevention recognition award - Bronze distinction"
      },
      ACQ2017:{
        "name": "ASSOCIATION DE LA CONSTRUCTION DU QUÉBEC",
        "logo": ACQLogo,
        "year": 2017,
        "description": "\"Construire\" trophy - Metropolitan area"
      },
      ACQ2018:{
          "name": "ASSOCIATION DE LA CONSTRUCTION DU QUÉBEC",
          "logo": ACQLogo,
          "year": 2018,
          "description": "Prevention mutual trophy"
      },
  },
  fr:{
      ACQ2014:{
          "name": "ASSOCIATION DE LA CONSTRUCTION DU QUÉBEC",
          "logo": ACQLogo,
          "year": 2014,
          "description": "Banquet reconnaissance 2014 - Mutuelles de prévention ACQ"
      },
      CCEM:{
        "name": "CHAMBRE DE COMMERCE DE L'EST DE MONTRÉAL",
        "logo": CCEMLogo,
        "year": 2015,
        "description": "Lauréat ESTim - Entreprise de la construction"
      },
      GPSST:{
        "name": "LES GRANDS PRIX SANTÉ ET SÉCURITÉ DU TRAVAIL",
        "logo": GPSSTLogo,
        "year": 2015,
        "description": "Finaliste régional - Innovation - Grandes entreprises"
      },
      IE:{
        "name": "INFO-EXCAVATION",
        "logo": IELogo,
        "year": 2016,
        "description": "Prix reconnaissance en prévention des dommages - Mention Bronze"
      },
      ACQ2017:{
        "name": "ASSOCIATION DE LA CONSTRUCTION DU QUÉBEC",
        "logo": ACQLogo,
        "year": 2017,
        "description": "Trophée Construire - Région Métropolitaine"
      },
      ACQ2018:{
          "name": "ASSOCIATION DE LA CONSTRUCTION DU QUÉBEC",
          "logo": ACQLogo,
          "year": 2018,
          "description": "Banquet reconnaissance 2018 - Mutuelles de prévention ACQ"
      },
  }
};

const AwardsDisplay = ({ language, title }: { language: string, title: string }) => {
  const awards = AwardsData[language];

  return (
    <div className="awards-section">
      <div className="awards-container">
        <h2 className="awards-title">{title}</h2>
        <div className="awards-gallery">
          {Object.keys(awards).map((key) => {
            const award = awards[key];
            return (
              <div key={key} className="award-item">
                <img src={award.logo} alt={award.name} className="award-logo"/>
                <div className="award-name">{award.name}</div>
                <div className="award-year">{award.year}</div>
                <div className="award-desc">{award.description}</div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default function About() {
  const { t } = useTranslation();
  const language = i18n.language; // make sure to import i18n
  const companyValues = language === 'fr' ? CompanyValues.values_fr : CompanyValues.values_en;
  const currentView = useDetectView();

  return (
    <div>
      <SingleLayerIntroSection title={t("history.intro.title")} backgroundImage={IntroImage}/>
      <div className="page-container">
        <motion.div
          transition={{ type: "spring", stiffness: 400, damping: 17, duration: 3 }}
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.9 }}>
          <motion.div initial="hidden" animate="visible" variants={{ visible: { opacity: 1 }, hidden: { opacity: 0 }, }} transition={{ duration: 2 }}
          whileInView={{ backgroundColor: ['#ffffff', '#f3f3f3'], color: ['#ffffff', '#101a2c'], boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)"}}
          style={{borderRadius: currentView!==View.Mobile ? "25px":"0px"}} className='history-section'>
            <div className="text-container-sm justify-text"> 
              <h1>{t("history.content.history.title")}</h1>
              <h2>{t("history.content.history.subtitle")}</h2>
              <div>{t("history.content.history.text")}</div>
              <div>{t("history.content.pillars.text")}</div>
              <div>{t("history.content.mission.text")}</div>
            </div>
          </motion.div>
        </motion.div>
        <div>
          <HAngledBackgroundTextSection>
          <div className="values-section">
            <div className="values-title-container">
              <h1>{t("history.content.values.title")}<span>.</span></h1>
            </div>
            <div className="values-grid">
              {companyValues.map((valueItem, index) => (
                <div key={index} className="value-item">
                  <h2>{valueItem.value}</h2>
                  <p>{valueItem.description}</p>
                </div>
              ))}
            </div>
          </div>
          </HAngledBackgroundTextSection>
          <div>
            <div>
              <AwardsDisplay language={language} title={t("history.content.awards.title")} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

