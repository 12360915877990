import { useState, useRef, useEffect, useCallback} from "react";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";
import './ImageSlider.scss';
import { motion } from "framer-motion";
import useDetectView from "../../Hook/useDetectView";
import { View } from "../../../Data/Constants";
import { useSwipeable } from "react-swipeable";

interface Slide {
    url: string;
  }
  
interface ImageSliderProps {
    slides: Slide[];
    parentWidth: string;
    parentHeight: string;
    autoplay?: boolean
}

export const ImageSlider = ({ slides = [], parentWidth, parentHeight, autoplay}: ImageSliderProps) => {
    const timerRef = useRef<NodeJS.Timeout | null>(null);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [transitionEnabled, setTransitionEnabled] = useState(true);
    const currentView = useDetectView();
    const [isArrowHovering, setIsHovering] = useState(false);
    const handlers = useSwipeable({
        onSwipedLeft: () => goToNext(),
        onSwipedRight: () => goToPrevious(),
        trackMouse: true
    });

    const goToPrevious = () => {
        let newIndex;
        if (currentIndex === 0) {
            newIndex = slides.length - 1;
            // Additional logic to handle the direct transition
            setTransitionEnabled(false);
            setCurrentIndex(newIndex);
            setTimeout(() => setTransitionEnabled(true), 0);
        } else {
            newIndex = currentIndex - 1;
            setCurrentIndex(newIndex);
        }
    }

    const goToNext = useCallback(() => {
        let newIndex;
        if (currentIndex === slides.length - 1) {
            newIndex = 0;
            // Additional logic to handle the direct transition
            setTransitionEnabled(false);
            setCurrentIndex(newIndex);
            setTimeout(() => setTransitionEnabled(true), 0);
        } else {
            newIndex = currentIndex + 1;
            setCurrentIndex(newIndex);
        }
    }, [currentIndex, slides]);
    
    /*
    const goToSlide = (slideIndex: number) => {
        setCurrentIndex(slideIndex)
    }
    */

    const borderRadiusNum = currentView===View.Mobile ? '0px' : '0px'

    const GetSlidesContainerStylesWithWidth = () => ({
    width: `${100 * slides.length}%`,
    display: 'flex',
    borderRadius: borderRadiusNum,
    transform: `translateX(-${(currentIndex * 100) / slides.length}%)`,
    transition: transitionEnabled ? 'transform ease-out 0.3s' : 'none',
});
    
    const GetSlideStylesWithBackground = (slideIndex: number) => ({
        backgroundImage: `url(${slides[slideIndex].url})`,
        width: `${100 / slides.length}%`,
        borderRadius: borderRadiusNum
    })

    /* AUTO SLIDER */
    useEffect(() => {
        if (autoplay) {
            if (timerRef.current)
            {
                clearTimeout(timerRef.current)
            }
            if (timerRef.current) clearTimeout(timerRef.current);
            timerRef.current = setTimeout(() => {
            goToNext();
            }, 5000);
            return () => {
            if (timerRef.current) clearTimeout(timerRef.current);
            };
        }
      }, [currentIndex]);

    return (
        <div className="image-slider-container" {...handlers}>
            <div className="slider-container">
                { currentView!==View.Mobile ?
                <div>
                    <motion.div 
                    className="slider-arrow-left-container" 
                    onClick={goToPrevious}>
                        <div className="arrow-left">
                            <FaAngleLeft/>
                        </div>
                    </motion.div>
                    <motion.div className="slider-arrow-right-container" onClick={goToNext}>
                        <div className="arrow-right">
                            <FaAngleRight/>
                        </div>
                    </motion.div>
                </div>
                : <></>
                }
                <div className="slide-container-overflow-protection">
                    <div className="slide-container" style={GetSlidesContainerStylesWithWidth()}>
                        {slides.map((_, slideIndex) => (
                        <div
                            key={slideIndex}
                            className="slide"
                            style={GetSlideStylesWithBackground(slideIndex)}></div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

