import './Privacy.scss'

export function Privacy_en() {

      return (
        <div>
        <div className="privacy-policy justify-text">
          <h1>Privacy Policy</h1>
          <p className="privacy-section-title">1. Sccope</p>
          <blockquote>
            <p>This privacy policy (hereinafter: the "Policy") applies to the
              following entities:</p>
          </blockquote>
          <ul>
            <li><p>CONSTRUCTION SIMDEV INC.</p></li>
            <li><p>DLS PROPERTY MANAGEMENT INC.</p></li>
            <li><p>CONDOS LEVIVO LA PRAIRIE INC.</p></li>
            <li><p>CONDOS LEVIVO GREENFIELD PARK INC.</p></li>
            <li><p>CONDOS LEVIVO LONGUEUIL CHAMBLY INC.</p></li>
            <li><p>And any other affiliated entities (hereinafter: "Simdev" or
              "we").</p></li>
          </ul>
          <blockquote>
            <p>The Privacy Policy applies to Personal Information about our clients,
              contractors, promising-buyers, employees and job applicants as well as
              Personal Information about the directors of our financial partners and
              representatives of our subcontractors in their use of the
              https://simdev.ca website, websites created specifically for Simdev's
              projects (hereinafter collectively the "Website") as well as Personal
              Information collected through our social media accounts, including
              Facebook, Instagram, LinkedIn (collectively, the "Social Media
              Accounts")</p>
          </blockquote>
          <p>Our Privacy Policy sets out our procedures for managing and
            protecting personal information, in accordance with the provisions of
            the Act respecting the protection of personal information in the private
            sector. The elements below detail:</p>
          <ul>
            <li><p>Our guiding principles and the scope of your consent;</p></li>
            <li><p>The types of personal information we collect, the methods we
              collect, and their purposes;</p></li>
            <li><p>The security and governance measures put in place to ensure their
              protection;</p></li>
            <li><p>Our approach to communicating, retaining, and sharing this
              information;</p></li>
            <li><p>Your rights in relation to them and the steps you take to
              exercise them.</p></li>
          </ul>
          <p className="privacy-section-title">2. General Principles</p>
          <blockquote>
            <p>Simdev is committed to the following principles in its internal and
              day-to-day operations:</p>
          </blockquote>
          <ul>
            <li><p>We require your clear, voluntary, and informed consent for the
              collection, use, or disclosure of your personal data. This consent must
              be given in view of the specific purposes set out in our policy in
              accordance with Article 3.</p></li>
            <li><p>The purposes for which Simdev collects your personal information,
              as described in Section 4 of our policy, are pre-established before or
              at the time of collection.</p></li>
            <li><p>Unless expressly or implied by you, or unless otherwise required
              by law, we use and share your personal information only for the specific
              purposes set out in Section 4 or for purposes compatible with
              them.</p></li>
            <li><p>Access to personal information by Simdev employees is strictly
              limited to those individuals whose information is necessary for their
              respective functions and tasks.</p></li>
            <li><p>We acknowledge that the disclosure of your personal information
              to third parties requires your consent, except as specified in Section 6
              of our Policy or as otherwise permitted or required by law.</p></li>
            <li><p>We only retain your personal information for as long as necessary
              to fulfil the purposes for which it was collected, in accordance with
              clause 7 of our policy.</p></li>
            <li><p>We strive to keep personal information accurate, complete, and
              up-to-date. However, you have a duty or right, as the case may be, to
              rectify them in accordance with the terms of section 8 of our
              policy.</p></li>
            <li><p>Simdev takes reasonable steps to enable you to exercise the
              rights granted to you by law, in accordance with Article 8 of our
              policy. Acceptance and Consent</p></li>
          </ul>
          <p className="privacy-section-title">3. Acceptance and Consent</p>
          <blockquote>
            <p>By accessing the website or sharing your personal information with
              our representatives through our social media accounts, you expressly
              consent to the collection, use, disclosure and retention of such data in
              accordance with our applicable policy. We therefore ask you to read this
              policy carefully. If you do not agree to these terms, please stop using
              the Website or our services.</p>
            <p>When you provide your Personal Information through Social Media
              Accounts, the privacy policies of those platforms may apply. We cannot
              guarantee that these platforms will respect the exercise of your rights.
              Therefore, please carefully read their policies regarding the protection
              of personal information provided using them:</p>
          </blockquote>
          <ul>
            <li><p>https://www.facebook.com/privacy/policy (Instagram and
              Facebook).</p></li>
            <li><p>https://fr.linkedin.com/legal/privacy-policy (LinkedIn).</p></li>
          </ul>
          <blockquote>
            <p>Depending on the nature and sensitivity of your Personal Information,
              your consent may be express (such consent may be given verbally, in
              writing, or electronically, for example, by checking a box confirming
              your consent or by signing a form requesting your consent), or implied
              (when you voluntarily provide Personal Information, for example, by
              filling out a form).</p>
            <p>You have the right to withdraw your consent at any time upon
              reasonable notice to Simdev. Please note that if you choose to withdraw
              your consent to the collection, use or disclosure of your Personal
              Information, certain features of our Website and/or services may no
              longer be available or available to you. We reiterate that we cannot
              guarantee that social media platforms will respect the exercise of your
              rights, so it is your responsibility to inform yourself of their privacy
              policies.</p>
          </blockquote>
          <p className="privacy-section-title">4. Personal Information Collected, Means and Purposes of
            Collection</p>
          <blockquote>
            <p>"Personal Information" means any information relating to a natural
              person that directly or indirectly identifies that individual.</p>
            <p>Each of Simdev's internal entities and departments collects only the
              Personal Information about you that is necessary to establish, manage
              and maintain its relationship with you. For each of these entities and
              departments, we describe below the categories of Personal Information
              collected, the means by which we do so, and the legitimate purposes we
              pursue in doing so.</p>
            <p>If we collect other Personal Information on an ad hoc basis as
              required by us, we will ask for your consent in accordance with Section
              3 and the general principles described in Section 2 that apply.</p>
          </blockquote>
          <div className='alphabetical-list'>
            <ol type="a">
              <h3><li>Property Management (Residential Rental)</li></h3>
            </ol>
            <div className="sub-alphabetical-list">
              <blockquote>
                <p>Simdev's residential rental division collects the following Personal
                  Information about tenants:</p>
              </blockquote>
              <ol type="i">
                <li><p>Identification information: first and last name, date of birth,
                  gender, and identification (e.g., health insurance card or driver's
                  license with redacted numbers);</p></li>
                <li><p>Contact information: mailing address, telephone number,
                  e-mail;</p></li>
                <li><p>Professional information: employer, job held, seniority, salary,
                  proof of income (for example: pay stub to find out the monthly net
                  salary);</p></li>
                <li><p>Financial information: void cheque and credit score;</p></li>
                <li><p>Rental history information: information related to the previous
                  lease (duration, expiry, current rent amount, etc.), reference
                  information (name and phone number of previous landlords, a
                  representative of the current employer, etc.);</p></li>
                <li><p>Other information (depending on the building): Licence plate (for
                  automated parking access systems and emergencies).</p></li>
              </ol>
              <blockquote>
                <p>This Personal Information may be collected during your telephone
                  conversations or email exchanges with our representatives or when you
                  complete lease or credit inquiry forms. It can also be collected via
                  platforms such as Sales Force, HOPEM or building stack.</p>
                <p>In addition, some of this information may be provided to us by third
                  parties such as credit investigation firms, collectors, banks and
                  external firms that help foreign residents settle in Québec.</p>
                <p>This Personal Information is necessary for the fulfillment of the
                  following purposes:</p>
              </blockquote>
              <ol type="i">
                <li><p>rent out residential units;</p></li>
                <li><p>establish and maintain our relationship with you and provide you
                  with ongoing service;</p></li>
                <li><p>obtain statistics on your satisfaction;</p></li>
                <li><p>validate your ability to pay;</p></li>
                <li><p>check your rental history;</p></li>
                <li><p>provide you with notices and information;</p></li>
                <li><p>provide services to you through third parties;</p></li>
                <li><p>manage our rental stock; and</p></li>
                <li><p>meet the requirements of the law.</p></li>
              </ol>
              <blockquote>
                <p>In some cases, this Personal Information may be used in legal
                  proceedings (including before the Administrative Housing Tribunal),
                  including civil proceedings relating to the recovery of unpaid rent.</p>
              </blockquote>
            </div>
          </div>
          <div className='alphabetical-list'>
            <ol type="a" start={2}>
              <h3><li>Commercial Leasing</li></h3>
            </ol>
            <div className="sub-alphabetical-list">
              <blockquote>
                <p>Simdev's Commercial Leasing Department collects the following
                  Personal Information about tenants and/or their representatives, when
                  the tenant is a legal entity:</p>
              </blockquote>
              <ol type="i">
                <li><p>Identification information: first and last name, date of
                  birth;</p></li>
                <li><p>Contact information: mailing address, telephone number,
                  e-mail;</p></li>
                <li><p>Professional information: address of current job, name of
                  employer, duties performed;</p></li>
                <li><p>Financial information: void cheque, credit information, personal
                  balance sheet.</p></li>
              </ol>
              <blockquote>
                <p>This Personal Information is collected by email or via a disclosure
                  form and is necessary for the fulfillment of the following purposes:</p>
              </blockquote>
              <ol type="i">
                <li><p>leasing commercial space to you;</p></li>
                <li><p>validate your ability to pay;</p></li>
                <li><p>Guarantee payments through a personal guarantor.</p></li>
                <li><p>In some cases, this Personal Information may be used in legal
                  actions, including civil proceedings relating to the recovery of unpaid
                  rent.</p></li>
              </ol>
            </div>
          </div>
          <div className='alphabetical-list'>
            <ol type="a" start={3}>
              <h3><li>Sales (condo clients)</li></h3>
            </ol>
            <div className="sub-alphabetical-list">
              <blockquote>
                <p>Simdev's department and/or affiliated entities dedicated to the sale
                  of units in condominiums (condos) collects the following Personal
                  Information from prospective buyers:</p>
              </blockquote>
              <ol type="i">
                <li><p>Identification information: first and last name, address,
                  telephone number, email, gender, identity document (health insurance
                  card, driver's license, passport), nationality, marital status
                  information;</p></li>
                <li><p>Financial information: mortgage approval, banking
                  information;</p></li>
              </ol>
              <blockquote>
                <p>This Personal Information may be collected during email exchanges
                  with our representatives, when you sign a preliminary contract (and/or
                  an amendment or appendix thereto) or, if applicable, when you complete
                  any other form necessary for the sale of a condo unit. It is also
                  possible that these are provided to us by third parties, such as real
                  estate brokers, banks and notaries.</p>
                <p>This Personal Information is necessary for the fulfillment of the
                  following purposes:</p>
              </blockquote>
              <ol type="i">
                <li><p>sell you a unit, parking and/or storage space in a
                  condominium;</p></li>
                <li><p>provide you with mandatory notices, and generally provide you
                  with information relevant to the sale;</p></li>
                <li><p>verify your identity and verify compliance with certain
                  conditions imposed by law. For example, we can verify whether you, as a
                  buyer, qualify as an investor or as a foreign resident;</p></li>
                <li><p>Manage the accounting related to sales and manage the execution
                  of the required customization work.</p></li>
              </ol>
            </div>
          </div>
          <div className='alphabetical-list'>
            <ol type="a" start={4}>
              <h3><li>Financial Investments</li></h3>
            </ol>
            <div className="sub-alphabetical-list">
              <blockquote>
                <p>Simdev's financial investment department collects the following
                  Personal Information from the directors of financial partners and
                  investors:</p>
              </blockquote>
              <ol type="i">
                <li><p>Identification information: first and last name, address, date of
                  birth, SIN (for those investing in a personal capacity only);</p></li>
                <li><p>Contact information: phone number, email.</p></li>
              </ol>
              <blockquote>
                <p>This Personal Information may be collected through email exchanges
                  with our representatives or through third parties such as credit
                  reporting firms.</p>
                <p>They are necessary for the fulfillment of the following purposes:</p>
              </blockquote>
              <ol type="i">
                <li><p>file tax returns related to Simdev projects;</p></li>
                <li><p>provide information to auditors and/or competent
                  authorities;</p></li>
                <li><p>manage Simdev's portfolio;</p></li>
                <li><p>issue payments;</p></li>
                <li><p>Obtain signatures or perform other transactions related to
                  financial transactions between companies.</p></li>
              </ol>
            </div>
          </div>
          <div className='alphabetical-list'>
            <ol type="a" start={5}>
              <h3><li>Human resources</li></h3>
            </ol>
            <div className="sub-alphabetical-list">
              <blockquote>
                <p>Simdev's Human Resources department collects the following categories
                  of Personal Information from employees:</p>
              </blockquote>
              <ol type="i">
                <li><p>Identification information: first and last name, date of birth,
                  vehicle information (for automated parking and emergency access), photo,
                  identification (including for the purposes of initial identification of
                  an employee and in the event of an accident/work stoppage),
                  SIN;</p></li>
                <li><p>Contact information: mailing address, phone number, email,
                  emergency contact phone number</p></li>
                <li><p>medical information: COVID-related vaccination information,
                  information related to workplace injuries and occupational illnesses
                  that occurred in the course of the employment relationship with
                  Simdev;</p></li>
                <li><p>Employment history information: information typically provided in
                  a resume, such as career and seniority jobs, salaries, and reference
                  names and telephone numbers (at the time of hiring);</p></li>
                <li><p>Financial information: void cheque (for payroll);</p></li>
                <li><p>This Personal Information may be collected through technology
                  platforms dedicated to human resources management and payroll management
                  (such as Maestro) or through external recruitment firms for information
                  typically provided in a resume.</p></li>
              </ol>
              <blockquote>
                <p>For job applicants, we collect Personal Information typically
                  provided in a resume, such as a list of jobs held during the career, as
                  well as identification and contact information such as first and last
                  names, telephone numbers and email address. Subsequently, the other
                  Personal Information listed above is collected as it becomes necessary
                  for the advancement of the hiring process.</p>
              </blockquote>
            </div>
          </div>
          <div className='alphabetical-list'>
            <ol type="a" start={6}>
              <h3><li>Construction</li></h3>
            </ol>
            <div className="sub-alphabetical-list">
              <blockquote>
                <p>Simdev's Building Construction Division collects the following
                  Personal Information from representatives of suppliers and
                  subcontractors:</p>
              </blockquote>
              <ol type="i">
                <li><p>Identification information: first and last name;</p></li>
                <li><p>Contact and professional information: email (at work), address
                  (at work), telephone number, position held.</p></li>
              </ol>
              <blockquote>
                <p>This Personal Information is collected on the Maestro and 4cast+
                  platforms in order to achieve the following purposes:</p>
              </blockquote>
              <ol start={3} type="i">
                <li><p>Handle transactions related to the construction of
                  buildings;</p></li>
                <li><p>Create supplier and subcontractor profiles on the Procore
                  platform;</p></li>
                <li><p>To provide you with information and various notices and
                  correspondence, including under signed contracts.</p></li>
              </ol>
              <blockquote>
                <p>In some cases, this Personal Information may be used in legal actions
                  or arbitration, in the event of a contractual claim.</p>
              </blockquote>
            </div>
          </div>
          <p className="privacy-section-title">5. Conservation</p>
          <blockquote>
            <p>We store your Personal Information only for as long as necessary to
              fulfill the purposes for which it was collected. However, in some cases
              where legal requirements or contractual interests require it, we may be
              required to retain it for a longer period of time.</p>
            <p>If you request it in writing to the email address provided at the end
              of this Policy, we may inform you of the specific length of time your
              Personal Information will be retained.</p>
            <p>In addition, we reserve the right to periodically establish policies
              for the deletion of Personal Information.</p>
          </blockquote>
          <p className="privacy-section-title">6. Vos droits</p>
          <blockquote>
            <p>With respect to your personal information, you have the following
              rights:</p>
          </blockquote>
          <ol type="a">
            <li><p>The right to be informed about the use, disclosure, retention and
              destruction of your personal information (the purpose addressed in this
              Policy).</p></li>
            <li><p>The right to access your personal information and obtain
              additional details about how we use, disclose, retain and destroy it, as
              appropriate.</p></li>
            <li><p>In certain situations, the right to object, restrict or withdraw
              your consent by sending us reasonable notice to the email address
              provided at the end of this Policy.</p></li>
            <li><p>The right to have your personal information corrected if it is
              inaccurate or incomplete, as well as the right to complete it.</p></li>
            <li><p>The right to have your personal information deleted if it is out
              of date or for which the purposes for which it was collected has been
              met, subject to our legal obligations.</p></li>
            <li><p>The right to be informed in the event of a privacy incident
              related to your personal information that may cause serious
              harm.</p></li>
          </ol>
          <blockquote>
            <p>To exercise these rights, please contact us by email at the address
              provided at the end of this Policy. We will respond to your request
              within 30 days of receiving it, unless the law provides for an extension
              of this period.</p>
            <p>Unless restricted by law, if you refuse to provide or correct your
              information, we will provide you with the reasons for the refusal.</p>
          </blockquote>
          <p className="privacy-section-title">7. Témoins de connexion (Cookies)</p>
          <blockquote>
            <p>Your use of the Website allows us to automatically compile certain
              information, known as cookies, which may include:</p>
          </blockquote>
          <ol type="a">
            <li><p>Your computer's Internet Protocol (or IP) address;</p></li>
            <li><p>Your geolocation area;</p></li>
            <li><p>The operating system you are using;</p></li>
            <li><p>Language preference;</p></li>
            <li><p>The identity of your Internet service provider;</p></li>
            <li><p>The date and time you accessed the Website;</p></li>
            <li><p>The previous Website that you visited and that provided you with
              a link to our Website; and</p></li>
            <li><p>The content viewed and downloaded to our Website or the number of
              visits.</p></li>
          </ol>
          <blockquote>
            <p>Generally, the cookies we use fall into three categories:</p>
          </blockquote>
          <ol type="a">
            <li><p>Technical cookies: Technical cookies are used throughout the
              navigation to facilitate the use of our Website. For example, a
              technical cookie may be used to remember your username to facilitate
              your login, to remember your preferences or selected options;</p></li>
            <li><p>Analytical cookies: These cookies are anonymous and are used to
              collect statistics about the use of our Website and user preferences.
              These cookies may be used to collect data about users' interactions with
              our Website, such as clicks and mouse movements. The information
              collected may be used to analyze user behavior and improve the browsing
              experience on our Website.</p></li>
            <li><p>Advertising Cookies: These cookies may be set by the Website or
              by other sites that serve advertising or advertisements. These cookies
              collect information anonymously and build a profile of you as a visitor.
              These cookies can also be used to track the effectiveness of
              advertisements and better target ads based on previous browsing
              behavior. Cookies will be used by the following social media: LinkedIn,
              Facebook, Tik Tok, as well as by partners such as Microsoft, Bing,
              Google, Google Analytics and OpenX.</p></li>
          </ol>
          <blockquote>
            <p>If you wish, you can set your browser to notify you when you receive
              cookies or to refuse them. It is possible to erase cookies from your
              computer's hard drive, block the creation of cookies, or receive a
              warning before a cookie is stored.</p>
            <p>However, by doing so, your use of our Website may be affected and you
              may no longer have access to all of its functions. We encourage you to
              consult the Ads Setting and Network Advertising Initiative websites and
              the instructions or help section of your browser for more information
              about cookies.</p>
          </blockquote>
          <p className="privacy-section-title">8. Responsable de la protection des Renseignements personnels</p>
          <blockquote>
            <p>The person in charge of the protection of personal information at
              Simdev is Mr. Redouane Fadadi.</p>
            <p>If you have any questions or requests regarding the Policy, you may
              send an email to the following address:</p>
            <p><a
              href="mailto:confidentialite@simdev.ca">confidentialite@simdev.ca</a></p>
          </blockquote>
          <p className="privacy-section-title">9. Modification</p>
          <blockquote>
            <p>Simdev reserves the right to modify the content of this Policy at any
              time. The most up-to-date version will be posted on our Website in the
              Privacy Policy section. We recommend that you review this section of our
              Website periodically.</p>
            <p>This Privacy Policy was last modified on November 30, 2023.</p>
          </blockquote>
        </div>
      </div>
      );
    }
    
    export default Privacy_en;