import React, { useEffect, useRef, useState } from 'react';

interface Dot {
  x: number;
  y: number;
  velocityX: number;
  velocityY: number;
}

const MovingDotsBackground: React.FC = () => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas?.getContext('2d');
    let animationFrameId: number;
    const dots: Dot[] = [];

    const spawnDot = () => {
        if (canvas){
            const velocity = 1;
            const dot: Dot = {
                x: Math.random() * canvas.width,
                y: Math.random() * canvas.height,
                velocityX: (Math.random() - 0.5) * velocity,
                velocityY: (Math.random() - 0.5) * velocity,
            };
            dots.push(dot);
        }
    };

    const update = () => {
      if (!ctx || !canvas) return;

      ctx.clearRect(0, 0, canvas.width, canvas.height);

      dots.forEach(dot => {
        dot.x += dot.velocityX;
        dot.y += dot.velocityY;

        if (dot.x < 0 || dot.x > canvas.width || dot.y < 0 || dot.y > canvas.height) {
          const index = dots.indexOf(dot);
          dots.splice(index, 1);
          spawnDot();
        }

        ctx.beginPath();
        ctx.arc(dot.x, dot.y, 2, 0, Math.PI * 2);
        ctx.fillStyle = 'rgba(0, 0, 0, 0.1)'; // This sets the dot color to red with 70% opacity
        ctx.fill();
        ctx.closePath();
      });

      animationFrameId = requestAnimationFrame(update);
    };

    for (let i = 0; i < 100; i++) {
      spawnDot();
    }

    update();

    const handleResize = () => {
      if (canvasRef.current && canvasRef.current.parentElement) {
        const { width, height } = canvasRef.current.parentElement.getBoundingClientRect();
        canvasRef.current.width = width;
        canvasRef.current.height = height;
        setDimensions({ width, height });
      }
    }

    const resizeObserver = new ResizeObserver(() => {
      // Throttle the resize event to avoid the loop issue
      requestAnimationFrame(handleResize);
    });
  
    if (canvasRef.current) {
      resizeObserver.observe(canvasRef.current);
    }
  
    // Cleanup
    return () => {
      if (canvasRef.current) {
        resizeObserver.unobserve(canvasRef.current);
      }
      cancelAnimationFrame(animationFrameId);
    };
  }, []);

  return <canvas ref={canvasRef} style={{ position: 'absolute', top: 0, left: 0 }} />;
};

export default MovingDotsBackground;
