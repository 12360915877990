import image1 from '../../../Assets/images/LeVIVO/LeVIVO_Greenfield_Park/04-01A.jpeg'
import image2 from '../../../Assets/images/LeVIVO/LeVIVO_Greenfield_Park/04-01B.jpeg'
import image3 from '../../../Assets/images/LeVIVO/LeVIVO_Greenfield_Park/04-01C.jpeg'
import image4 from '../../../Assets/images/LeVIVO/LeVIVO_Greenfield_Park/04-01D.jpeg'
import image5 from '../../../Assets/images/LeVIVO/LeVIVO_Greenfield_Park/04-01E.jpeg'
import image6 from '../../../Assets/images/LeVIVO/LeVIVO_Greenfield_Park/04-02.jpeg'
import image7 from '../../../Assets/images/LeVIVO/LeVIVO_Greenfield_Park/04-02A.jpeg'
import image8 from '../../../Assets/images/LeVIVO/LeVIVO_Greenfield_Park/04-03.jpeg'
import image9 from '../../../Assets/images/LeVIVO/LeVIVO_Greenfield_Park/04-05.jpeg'
import image10 from '../../../Assets/images/LeVIVO/LeVIVO_Greenfield_Park/04-06.jpeg'
import image11 from '../../../Assets/images/LeVIVO/LeVIVO_Greenfield_Park/04-07.jpeg'
import image12 from '../../../Assets/images/LeVIVO/LeVIVO_Greenfield_Park/04-08.jpeg'
import image13 from '../../../Assets/images/LeVIVO/LeVIVO_Greenfield_Park/04-09.jpeg'
import image14 from '../../../Assets/images/LeVIVO/LeVIVO_Greenfield_Park/04-10.jpeg'
import image15 from '../../../Assets/images/LeVIVO/LeVIVO_Greenfield_Park/04-11.jpeg'
import image16 from '../../../Assets/images/LeVIVO/LeVIVO_Greenfield_Park/04-12.jpg'
import image17 from '../../../Assets/images/LeVIVO/LeVIVO_Greenfield_Park/04-12A.jpeg'
import image18 from '../../../Assets/images/LeVIVO/LeVIVO_Greenfield_Park/04-13.jpg'
import image19 from '../../../Assets/images/LeVIVO/LeVIVO_Greenfield_Park/04-14.jpeg'
import image20 from '../../../Assets/images/LeVIVO/LeVIVO_Greenfield_Park/04-15.jpeg'
import image21 from '../../../Assets/images/LeVIVO/LeVIVO_Greenfield_Park/04-16.jpeg'



/* IMAGES LEVIVO GREENFIELD PARK*/

export const slidesLVGP = [
    {
        url: image1,
    },
    {
        url: image2,
    },
    {
        url: image19,
    },
    {
        url: image20,
    },
    {
        url: image21,
    },
    {
        url: image3,
    },
    {
        url: image4,
    },
    {
        url: image5,
    },
    {
        url: image6,
    },
    {
        url: image7,
    },
    {
        url: image8,
    },
    {
        url: image9,
    },
    {
        url: image10,
    },
    {
        url: image11,
    },
    {
        url: image12,
    },
    {
        url: image13,
    },
    {
        url: image14,
    },
    {
        url: image15,
    },
    {
        url: image16,
    },
    {
        url: image17,
    },
    {
        url: image18,
    },

]

export default slidesLVGP;

