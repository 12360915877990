import "../../i18n";
import { useTranslation } from "react-i18next";
import { View } from "../../Data/Constants";
import LocationImage from "../../Assets/images/Shutterstock/HappyCouple.jpeg"
import FinancingImage from "../../Assets/images/Shutterstock/Accountant.jpeg"
import MarketingImage from "../../Assets/images/LeVIVO/LeVIVO_Longueuil_2/05-07.jpeg"
import MaintenanceImage from "../../Assets/images/Shutterstock/MaintenanceWorker.jpeg"
import OptimizationImage from "../../Assets/images/Shutterstock/Financing.jpeg"
import SimpleTextSection from "../../Components/Text/SimpleTextSection/SimpleTextSection";
import useDetectView from "../../Components/Hook/useDetectView";
import './ManagementPage.scss'
import { Variants, motion, useAnimation } from "framer-motion";

//FOR TESTING PURPOSE
import IntroSectionImage from '../../Assets/images/LeVIVO/LeVIVO_LaPrairie/03-02.jpeg'
import SingleLayerIntroSection from "../../Components/Text/ParallaxTitle/Simple/SimpleParallaxTitle";
import { HAngledBackgroundTextSection } from "../../Components/Text/AngledBackgroundTextSection/AngledBackgroundTextSection";

export default function Construction(){
    const { t } = useTranslation();
    const currentView = useDetectView();

    let containerHeight = "500"; // Default height

    switch (currentView) {
    case View.Mobile:
        containerHeight = "auto";
        break;
    case View.Tablet:
        // Set height for tablet view, if different from default
        containerHeight = "850";
        break;
    default:
        break;
    }
    
    return (
        <div>
            <SingleLayerIntroSection title={t("management.intro.title")} backgroundImage={IntroSectionImage}/>
                
                {
                    // ITEM 1
                }
                <motion.div
                    className="management-card-container management-card-container-light-bg"
                    transition={{ type: "spring", stiffness: 400, damping: 17, duration: 3 }}
                    whileHover={currentView !== View.Mobile ? { scale: 1.05 } : {}}
                    whileTap={currentView !== View.Mobile ? { scale: 0.9 } : {}}
                    style={{boxShadow: currentView!==View.Mobile ? "0 4px 12px rgba(0, 0, 0, 0.1)" : "0 0 0 0"}}
                >
                    <div>
                        <SimpleTextSection 
                        title={t("management.services.rental.title")}
                        text={t("management.services.rental.text")}
                        isDark={false}
                        isTransparent={true}
                        height={containerHeight}
                        position="left" 
                        image={LocationImage} 
                        isRounded={currentView===View.Tablet || currentView===View.Desktop || currentView===View.LargeTablet ? true : false }
                        imagePosition="child"
                        imageFit="cover"
                        isImageRounded={true}
                        delayInSecond={0} 
                        durationInSecond={2}
                        enableShadow={false}/>
                    </div>
                </motion.div>
                {
                    // ITEM 2
                }
                <motion.div
                    className="management-card-container management-card-container-dark-bg"
                    transition={{ type: "spring", stiffness: 400, damping: 17, duration: 3 }}
                    whileHover={currentView !== View.Mobile ? { scale: 1.05 } : {}}
                    whileTap={currentView !== View.Mobile ? { scale: 0.9 } : {}}
                    style={{boxShadow: currentView!==View.Mobile ? "0 4px 12px rgba(0, 0, 0, 0.1)" : "0 0 0 0"}}
                >
                    <div>
                        <SimpleTextSection 
                        title={t("management.services.financing.title")} 
                        text={t("management.services.financing.text")} 
                        isDark={true} 
                        isTransparent={false}
                        height={containerHeight}
                        position="right" 
                        image={FinancingImage} 
                        isRounded={currentView===View.Tablet || currentView===View.Desktop || currentView===View.LargeTablet ? true : false }
                        imagePosition="child"
                        imageFit="cover"
                        isImageRounded={true}
                        delayInSecond={0} 
                        durationInSecond={2}
                        enableShadow={false}/>
                    </div>
                </motion.div>    
                {
                    // ITEM 3
                }
                <motion.div
                    className="management-card-container management-card-container-light-bg"
                    transition={{ type: "spring", stiffness: 400, damping: 17, duration: 3 }}
                    whileHover={currentView !== View.Mobile ? { scale: 1.05 } : {}}
                    whileTap={currentView !== View.Mobile ? { scale: 0.9 } : {}}
                    style={{boxShadow: currentView!==View.Mobile ? "0 4px 12px rgba(0, 0, 0, 0.1)" : "0 0 0 0"}}
                >
                    <div>
                        <SimpleTextSection 
                        title={t("management.services.marketing.title")}
                        text={t("management.services.marketing.text")}
                        isDark={false} 
                        isTransparent={true}
                        position="left" 
                        height={containerHeight}
                        image={MarketingImage} 
                        isRounded={currentView===View.Tablet || currentView===View.Desktop || currentView===View.LargeTablet ? true : false }
                        imagePosition="child"
                        imageFit="cover"
                        isImageRounded={true}
                        delayInSecond={0} 
                        durationInSecond={2}
                        enableShadow={false}/>
                    </div>
                </motion.div>
                {
                    // ITEM 4
                }
                <motion.div
                    className="management-card-container management-card-container-dark-bg"
                    transition={{ type: "spring", stiffness: 400, damping: 17, duration: 3 }}
                    whileHover={currentView !== View.Mobile ? { scale: 1.05 } : {}}
                    whileTap={currentView !== View.Mobile ? { scale: 0.9 } : {}}
                    style={{boxShadow: currentView!==View.Mobile ? "0 4px 12px rgba(0, 0, 0, 0.1)" : "0 0 0 0"}}
                >
                    <div>
                        <SimpleTextSection 
                            title={t("management.services.maintenance.title")}
                            text={t("management.services.maintenance.text")}
                            isDark={true}
                            isTransparent={false}
                            position="right"
                            height={containerHeight}
                            image={MaintenanceImage} 
                            isRounded={currentView===View.Tablet || currentView===View.Desktop || currentView===View.LargeTablet ? true : false }
                            imagePosition="child"
                            imageFit="cover"
                            isImageRounded={true}
                            delayInSecond={0} 
                            durationInSecond={2}
                            enableShadow={false}/>
                    </div>
                </motion.div>
                {
                    // ITEM 5
                }
                <motion.div
                    className="management-card-container management-card-container-light-bg"
                    transition={{ type: "spring", stiffness: 400, damping: 17, duration: 3 }}
                    whileHover={currentView !== View.Mobile ? { scale: 1.05 } : {}}
                    whileTap={currentView !== View.Mobile ? { scale: 0.9 } : {}}
                    style={{boxShadow: currentView!==View.Mobile ? "0 4px 12px rgba(0, 0, 0, 0.1)" : "0 0 0 0"}}
                >
                    <div>
                        <SimpleTextSection 
                            title={t("management.services.optimization.title")}
                            text={t("management.services.optimization.text")}
                            isDark={false}
                            isTransparent={true} 
                            position="left" 
                            height={containerHeight}
                            image={OptimizationImage} 
                            isRounded={currentView===View.Tablet || currentView===View.Desktop || currentView===View.LargeTablet ? true : false }
                            imagePosition="child"
                            imageFit="cover"
                            isImageRounded={true}
                            delayInSecond={0} 
                            durationInSecond={2}
                            enableShadow={false}/>
                    </div>
                </motion.div>
            </div>
    )
}

