import './Privacy.scss'

export function Privacy_fr() {

      return (
        <div>
        <div className="privacy-policy">
            <h1>Politique de confidentialité</h1>
            <div className='justify-text'>
                <p className="privacy-section-title">1. Portée</p>
                <blockquote>
                    <p>La présente politique de confidentialité (ci-après : la
                        « Politique ») s’applique aux entités suivantes :</p>
                </blockquote>
                <ul>
                    <li><p>CONSTRUCTION SIMDEV INC.</p></li>
                    <li><p>GESTION IMMOBILIÈRE DLS INC.</p></li>
                    <li><p>CONDOS LEVIVO LA PRAIRIE INC.</p></li>
                    <li><p>CONDOS LEVIVO GREENFIELD PARK INC.</p></li>
                    <li><p>CONDOS LEVIVO LONGUEUIL CHAMBLY INC.</p></li>
                    <li><p>Et tout autre entités affiliées (ci-après : « Simdev » ou
                        « nous »).</p></li>
                </ul>
                <blockquote>
                    <p>La Politique de confidentialité s’applique aux Renseignements
                        personnels concernant nos clients, cocontractants, promettant-acheteurs,
                        employés et candidats à l’emploi ainsi que ceux concernant les
                        administrateurs de nos partenaires financiers et les représentants de
                        nos sous-traitants à leur utilisation du site web https://simdev.ca, les
                        sites web créés spécifiquement pour les projets de simdev (ci-après
                        collectivement le « Site Web ») ainsi qu’aux Renseignements personnels
                        recueillis via nos comptes sur les réseaux sociaux, incluant Facebook,
                        instagram, LinkedIn (ci-après collectivement les « Comptes sur les
                        réseaux sociaux »)</p>
                </blockquote>
                <p>Notre politique de confidentialité énonce nos procédures de gestion
                    et de protection des informations personnelles, conformément aux
                    dispositions de la Loi sur la protection des renseignements personnels
                    dans le secteur privé. Les éléments ci-dessous détaillent :</p>
                <ul>
                    <li><p>Nos principes directeurs et la portée de votre consentement
                        ;</p></li>
                    <li><p>Les types d'informations personnelles que nous collectons, les
                        méthodes de collecte et leurs objectifs ;</p></li>
                    <li><p>Les mesures de sécurité et de gouvernance mises en place pour
                        assurer leur protection ;</p></li>
                    <li><p>Notre approche en matière de communication, de conservation et de
                        partage de ces informations ;</p></li>
                    <li><p>Vos droits à leur égard et les démarches pour les
                        exercer.</p></li>
                </ul>
                <p className="privacy-section-title">2. Principes généraux</p>
                <blockquote>
                    <p>Simdev s'engage à respecter les principes suivants dans ses
                        opérations internes et courantes :</p>
                </blockquote>
                <ul>
                    <li><p>Nous requérons votre consentement clair, volontaire et éclairé
                        pour la collecte, l'utilisation ou la communication de vos données
                        personnelles. Ce consentement doit être donné en vue des objectifs
                        spécifiques énoncés dans notre politique conformément à l'article
                        3.</p></li>
                    <li><p>Les objectifs pour lesquels Simdev collecte vos informations
                        personnelles, comme décrits dans l'article 4 de notre politique, sont
                        préétablis avant ou au moment de la collecte.</p></li>
                    <li><p>Sauf autorisation expresse ou tacite de votre part, ou sauf si la
                        loi l'exige autrement, nous utilisons et partageons vos informations
                        personnelles uniquement pour les objectifs spécifiques énoncés à
                        l'article 4 ou pour des objectifs compatibles avec ceux-ci.</p></li>
                    <li><p>L'accès aux informations personnelles par les employés de Simdev
                        est strictement limité aux personnes dont ces informations sont
                        nécessaires pour leurs fonctions et tâches respectives.</p></li>
                    <li><p>Nous reconnaissons que la communication de vos informations
                        personnelles à des tiers requiert votre consentement, sauf dans les cas
                        précisés à l'article 6 de notre politique ou lorsqu'autrement autorisé
                        ou requis par la loi.</p></li>
                    <li><p>Nous ne conservons vos informations personnelles que pendant la
                        durée nécessaire à la réalisation des objectifs pour lesquels elles ont
                        été collectées, conformément à l'article 7 de notre politique.</p></li>
                    <li><p>Nous nous efforçons de maintenir des informations personnelles
                        exactes, complètes et à jour. Toutefois, vous avez le devoir ou le
                        droit, selon les cas, de les rectifier en accord avec les modalités de
                        l'article 8 de notre politique.</p></li>
                    <li><p>Simdev met en place des mesures raisonnables pour vous permettre
                        d'exercer les droits qui vous sont accordés par la loi, conformément à
                        l'article 8 de notre politique. Acceptation et consentement</p></li>
                </ul>
                <p className="privacy-section-title">3. Acceptation et consentement</p>
                <blockquote>
                    <p>En accédant au site web ou en partageant vos informations
                        personnelles avec nos représentants via nos comptes sur les réseaux
                        sociaux, vous consentez expressément à la collecte, l'utilisation, la
                        communication et la conservation de ces données conformément à notre
                        politique en vigueur. Nous vous prions donc de lire attentivement cette
                        politique. Si vous n'acceptez pas ces termes, veuillez cesser d'utiliser
                        le site web ou nos services.</p>
                    <p>Lorsque vous communiquez vos Renseignements personnels via les
                        Comptes sur les réseaux sociaux, les politiques de confidentialité de
                        ces plateformes peuvent s’appliquer. Nous ne pouvons garantir que ces
                        plateformes respecteront l’exercice de vos droits. Veuillez donc lire
                        attentivement leurs politiques relatives à la protection des
                        renseignements personnels communiqués en utilisant ceux-ci :</p>
                </blockquote>
                <ul>
                    <li><p>https://www.facebook.com/privacy/policy (Instagram et
                        Facebook).</p></li>
                    <li><p>https://fr.linkedin.com/legal/privacy-policy (LinkedIn).</p></li>
                </ul>
                <blockquote>
                    <p>En fonction de la nature et de la sensibilité de vos Renseignements
                        personnels, votre consentement peut être explicite (un tel consentement
                        peut être donné verbalement, par écrit ou par voie électronique, par
                        exemple, en cochant une case confirmant votre consentement ou en
                        apposant votre signature sur un formulaire demandant votre
                        consentement), ou implicite (lorsque vous fournissez volontairement des
                        Renseignements personnels, par exemple en remplissant un
                        formulaire).</p>
                    <p>Vous avez le droit de retirer votre consentement en tout temps, sur
                        transmission d’un préavis raisonnable à Simdev. Veuillez noter que si
                        vous choisissez de retirer votre consentement à la collecte, à
                        l’utilisation ou à la communication de vos Renseignements personnels,
                        certaines fonctionnalités de notre Site Web et/ou certains services
                        pourraient ne plus vous être accessibles ou offerts. Nous réitérons que
                        nous ne pouvons garantir que les plateformes de réseaux sociaux
                        respecterons l'exercice de vos droits alors il est de votre
                        responsabilité de vous informer de leurs politiques relatives à la
                        protections des renseignements personnels.</p>
                </blockquote>
                <p className="privacy-section-title">4. Renseignements personnels recueillis, moyens et finalités de la collecte</p>
                <blockquote>
                    <p>Par « Renseignements personnels », on entend tout renseignement se
                        rapportant à une personne physique et qui permet, directement ou
                        indirectement, de l’identifier.</p>
                    <p>Chacune des entités et chacun des départements internes de Simdev
                        recueille uniquement les Renseignements personnels vous concernant qui
                        sont nécessaires afin d’établir, de gérer et de maintenir les relations
                        qu’elle entretient avec vous. Pour chacune de ces entités et chacun des
                        départements, nous décrivons ci-après les catégories de Renseignements
                        personnels recueillis, les moyens par lesquels nous le faisons ainsi que
                        les fins légitimes que nous poursuivons en ce faisant.</p>
                    <p>Si nous recueillons d’autres Renseignements personnels de manière
                        ponctuelle selon nos besoins, nous vous demanderons votre consentement
                        conformément à l’article 3 et aux principes généraux décrits à l’article
                        2 qui sont applicables.</p>
                </blockquote>
                <div className='alphabetical-list'>
                    <ol type="a">
                        <h3><li>Gestion immobilière (location résidentielle)</li></h3>
                    </ol>
                    <div className="sub-alphabetical-list">
                        <blockquote>
                            <p>La division de Simdev dédiée à la location résidentielle recueille
                                les Renseignements personnels suivants sur les locataires:</p>
                        </blockquote>
                        <ol type="i">
                            <li><p>renseignements d’identification : prénom et nom, date de
                                naissance, sexe et pièces d’identité (par exemple carte d’assurance
                                maladie ou permis de conduite dont les numéros sont caviardés);</p></li>
                            <li><p>renseignements de contact : adresse postale, numéro de téléphone,
                                courriel;</p></li>
                            <li><p>renseignements professionnels : employeur, emploi occupé,
                                ancienneté, salaire, preuves de revenus (par exemple : relevé de paie
                                pour connaître le salaire net mensuel);</p></li>
                            <li><p>renseignements financiers : spécimen de chèque et pointage de
                                crédit;</p></li>
                            <li><p>renseignements sur l’historique de location : informations
                                reliées à l’ancien bail (durée, expiration, montant du loyer actuel,
                                etc.), renseignements de références (nom et numéro de téléphone
                                d’anciens locateurs, d’un représentant de l’employeur actuel,
                                etc.);</p></li>
                            <li><p>autres renseignements (selon les immeubles) : plaque
                                d’immatriculation (pour les systèmes d’accès automatisés aux
                                stationnements et les urgences).</p></li>
                        </ol>
                        <blockquote>
                            <p>Ces Renseignements personnels peuvent être recueillis lors de vos
                                conversations téléphoniques ou échanges de courriels avec nos
                                représentants ou lorsque vous remplissez des formulaires de bail ou
                                d’enquête de crédit. Ils peuvent aussi être recueillis via des
                                plateformes telles que Sales Force, HOPEM ou building stack.</p>
                            <p>Par ailleurs, certains de ces renseignements peuvent nous être
                                fournis par des tiers tels que des firmes d’enquête de crédit, des
                                agents de collection, des banques ainsi que des firmes externes aidant
                                des résidents étrangers à s’établir au Québec.</p>
                            <p>Ces Renseignements personnels sont nécessaires à l’accomplissement
                                des finalités suivantes :</p>
                        </blockquote>
                        <ol type="i">
                            <li><p>louer des unités résidentielles;</p></li>
                            <li><p>établir et maintenir notre relation avec vous et vous offrir un
                                service continu;</p></li>
                            <li><p>obtenir des statistiques sur votre satisfaction;</p></li>
                            <li><p>valider votre capacité de payer;</p></li>
                            <li><p>vérifier vos antécédents de location;</p></li>
                            <li><p>vous transmettre des avis et des informations;</p></li>
                            <li><p>vous fournir des services par l’intermédiaire de tiers;</p></li>
                            <li><p>gérer notre parc locatif; et</p></li>
                            <li><p>répondre aux exigences de la loi.</p></li>
                        </ol>
                        <blockquote>
                            <p>Dans certains cas, ces Renseignements personnels peuvent servir lors
                                d’actions en justice (notamment devant le Tribunal administratif du
                                logement), incluant des procédures civiles relatives au recouvrement de
                                loyers impayés.</p>
                        </blockquote>
                    </div>
                </div>
                <div className='alphabetical-list'>
                    <ol type="a" start={2}>
                        <h3><li>Location commerciale</li></h3>
                    </ol>
                    <div className="sub-alphabetical-list">
                        <blockquote>
                            <p>Le département de Simdev dédié à la location commerciale recueille
                                les Renseignements personnels suivants sur les locataires et/ou leur(s)
                                représentants, lorsque le locataire est une personne morale:</p>
                        </blockquote>
                        <ol type="i">
                            <li><p>renseignements d’identification : prénom et nom, date de
                                naissance;</p></li>
                            <li><p>renseignements de contact : adresse postale, numéro de téléphone,
                                courriel;</p></li>
                            <li><p>renseignements professionnels : adresse de l’emploi actuel, nom
                                de l’employeur, fonctions exercées;</p></li>
                            <li><p>renseignements financiers : spécimen de chèque, informations de
                                crédit, bilan financier personnel.</p></li>
                        </ol>
                        <blockquote>
                            <p>Ces Renseignements personnels sont recueillis par courriel ou via un
                                formulaire de divulgation et sont nécessaires à l’accomplissement des
                                finalités suivantes :</p>
                        </blockquote>
                        <ol type="i">
                            <li><p>vous louer des locaux commerciaux;</p></li>
                            <li><p>valider votre capacité de payer;</p></li>
                            <li><p>garantir les paiements par l’intermédiaire d’une caution
                                personnelle.</p></li>
                            <li><p>Dans certains cas, ces Renseignements personnels peuvent servir
                                lors d’actions en justice, incluant des procédures civiles relatives au
                                recouvrement de loyers impayés.</p></li>
                        </ol>
                    </div>
                </div>
                <div className='alphabetical-list'>             
                    <ol type="a" start={3}>
                        <h3><li>Ventes (Clients Condos)</li></h3>
                    </ol>
                    <div className="sub-alphabetical-list">
                        <blockquote>
                            <p>Le département et/ou les entités affiliées de Simdev dédiés à la
                                vente d’unités dans des copropriétés (condos) recueille sur les
                                promettants-acheteurs les Renseignements personnels suivants :</p>
                        </blockquote>
                        <ol type="i">
                            <li><p>renseignements d’identification : prénom et nom, adresse, numéro
                                de téléphone, courriel, sexe, pièce d’identité (carte d’assurance
                                maladie, permis de conduire, passeport), nationalité, informations sur
                                l’état civil;</p></li>
                            <li><p>renseignements financiers : approbation hypothécaire,
                                informations bancaires;</p></li>
                        </ol>
                        <blockquote>
                            <p>Ces Renseignements personnels peuvent être recueillis lors d’échanges
                                de courriels avec nos représentants, lorsque vous signez un contrat
                                préliminaire (et/ou un amendement ou une annexe à celui-ci) ou, le cas
                                échéant, lorsque vous remplissez tout autre formulaire nécessaire à la
                                vente d’une unité de condo. Il est également possible que ceux-ci nous
                                soient fournis par des tiers, tels que des courtiers immobiliers, des
                                banques et des notaires.</p>
                            <p>Ces Renseignements personnels sont nécessaires à l’accomplissement
                                des finalités suivantes :</p>
                        </blockquote>
                        <ol type="i">
                            <li><p>vous vendre une unité, un stationnement et/ou un rangement dans
                                une copropriété;</p></li>
                            <li><p>vous transmettre des avis obligatoires, et de manière générale,
                                vous communiquer des informations pertinentes à la vente;</p></li>
                            <li><p>vérifier votre identité et vérifier le respect de certaines
                                conditions imposées par la loi. À titre d’exemple, nous pouvons vérifier
                                si à titre d’acheteurs, vous vous qualifiez comme investisseurs ou comme
                                résidents étrangers;</p></li>
                            <li><p>gérer la comptabilité en lien avec les ventes et gérer
                                l’exécution des travaux de personnalisations requis.</p></li>
                        </ol>
                    </div>
                </div>
                <div className='alphabetical-list'>  
                    <ol type="a" start={4}>
                        <h3><li>Investissements financiers</li></h3>
                    </ol>
                    <div className="sub-alphabetical-list">
                        <blockquote>
                            <p>Le département de Simdev dédié aux investissements financiers
                                recueille sur les administrateurs des partenaires financiers et
                                investisseurs les Renseignements personnels suivants :</p>
                        </blockquote>
                        <ol type="i">
                            <li><p>renseignements d’identification : prénom et nom, adresse, date de
                                naissance, NAS (pour ceux investissant à titre personnel
                                seulement);</p></li>
                            <li><p>renseignements de contact : numéro de téléphone,
                                courriel.</p></li>
                        </ol>
                        <blockquote>
                            <p>Ces Renseignements personnels peuvent être recueillis lors d’échanges
                                de courriels avec nos représentants ou par l’intermédiaire de tiers tels
                                que des firmes d’enquête de crédit.</p>
                            <p>Ils sont nécessaires à l’accomplissement des finalités suivantes
                                :</p>
                        </blockquote>
                        <ol type="i">
                            <li><p>effectuer les déclarations d’impôts liées aux projets de
                                Simdev;</p></li>
                            <li><p>transmettre des informations aux auditeurs et/ou autorités
                                compétentes;</p></li>
                            <li><p>gérer le portefeuille de Simdev;</p></li>
                            <li><p>émettre des paiements;</p></li>
                            <li><p>obtenir des signatures ou effectuer d’autres opérations reliées
                                aux transactions financières entre compagnies.</p></li>
                        </ol>
                    </div>
                </div>
                <div className='alphabetical-list'>
                    <ol type="a" start={5}>
                        <h3><li>Ressources humaines</li></h3>
                    </ol>
                    <div className="sub-alphabetical-list">
                        <blockquote>
                            <p>Le département des ressources humaines de Simdev recueille sur les
                                employés les catégories de Renseignements personnels suivants :</p>
                        </blockquote>
                        <ol type="i">
                            <li><p>renseignements d’identification : prénom et nom, date de
                                naissance, informations sur le véhicule (pour l’accès automatisé au
                                stationnement et les urgences), photo, pièce d’identité (notamment pour
                                fins d’indentification initiale d’un employé et en cas d’accident/arrêt
                                de travail), NAS;</p></li>
                            <li><p>renseignements de contact : adresse postale, numéro de téléphone,
                                courriel, numéro de téléphone d’un contact d’urgence</p></li>
                            <li><p>renseignements médicaux : informations sur la vaccination liées à
                                la COVID, renseignements liés aux accidents de travail et maladies
                                professionnelles survenus dans le cadre de la relation d’emploi avec
                                Simdev;</p></li>
                            <li><p>renseignements sur l’historique d’emploi : informations
                                généralement fournies dans un curriculum vitae telles que les emplois
                                occupés durant la carrière et l’ancienneté ainsi que les salaires et les
                                noms et numéros de téléphone de références (lors de
                                l’embauche);</p></li>
                            <li><p>renseignements financiers : spécimen de chèque (pour la
                                paie);</p></li>
                            <li><p>Ces Renseignements personnels peuvent être recueillis via des
                                plateformes technologiques dédiées à la gestion des ressources humaines
                                et à la gestion de la paie (telles que Maestro) ou via des firmes de
                                recrutement externe pour les renseignements généralement fournis dans un
                                curriculum vitae.</p></li>
                        </ol>
                        <blockquote>
                            <p>Concernant les candidats à l’emploi, nous recueillons les
                                Renseignements personnels généralement fournis dans un curriculum vitae
                                tels que la liste des emplois occupés durant la carrière ainsi que des
                                renseignements d’identification et de contact tels que les noms et
                                prénoms, numéros de téléphone et adresse courriel. Par la suite, les
                                autres Renseignements personnels listés ci-haut sont recueillis au fur
                                et à mesure qu’ils deviennent nécessaires à l’avancement du processus
                                d’embauche.</p>
                        </blockquote>
                    </div>
                </div>
                <div className='alphabetical-list'>
                    <ol type="a" start={6}>
                        <h3><li>Construction</li></h3>
                    </ol>
                    <div className="sub-alphabetical-list">
                        <blockquote>
                            <p>La division de Simdev dédiée à la construction d’immeubles recueille
                                sur les représentants des fournisseurs et des sous-traitants les
                                Renseignements personnels suivants :</p>
                        </blockquote>
                        <ol type="i">
                            <li><p>Renseignements d’identification : prénom et nom;</p></li>
                            <li><p>Renseignements de contact et renseignements professionnels :
                                courriel (au travail), adresse (du lieu de travail), numéro de
                                téléphone, poste occupé.</p></li>
                        </ol>
                        <blockquote>
                            <p>Ces Renseignements personnels sont notamment recueillis sur les
                                plateformes Maestro et 4cast+ en vue de réaliser les finalités suivantes
                                :</p>
                        </blockquote>
                        <ol start={3} type="i">
                            <li><p>Traiter les opérations liées à la construction
                                d’immeubles;</p></li>
                            <li><p>Créer des profils des fournisseurs et de sous-traitants sur la
                                plateforme Procore;</p></li>
                            <li><p>Vous transmettre de l’information et divers avis et
                                correspondances, notamment en vertu des contrats signés.</p></li>
                        </ol>
                        <blockquote>
                            <p>Dans certains cas, ces Renseignements personnels peuvent servir lors
                                d’actions en justice ou en matière d’arbitrage, en cas de réclamation
                                contractuelle.</p>
                        </blockquote>
                    </div>
                </div>
                <p className="privacy-section-title">5. Conservation</p>
                <blockquote>
                    <p>Nous stockons vos Renseignements personnels uniquement pour la
                        période nécessaire à l'accomplissement des objectifs pour lesquels ils
                        ont été collectés. Toutefois, dans certains cas où des exigences légales
                        ou des intérêts contractuels le requièrent, nous pourrions être
                        contraints de les conserver plus longtemps.</p>
                    <p>Si vous en faites la demande par écrit à l'adresse e-mail fournie à
                        la fin de cette Politique, nous pouvons vous informer de la durée
                        précise pendant laquelle vos Renseignements personnels seront
                        conservés.</p>
                    <p>De plus, nous nous réservons le droit d'établir périodiquement des
                        politiques de suppression des Renseignements personnels.</p>
                </blockquote>
                <p className="privacy-section-title">6. Vos droits</p>
                <blockquote>
                    <p>En ce qui concerne vos informations personnelles, vous bénéficiez des
                        droits suivants :</p>
                </blockquote>
                <ol type="a">
                    <li><p>Le droit d'être informé sur l'utilisation, la communication, la
                        conservation et la destruction de vos informations personnelles
                        (objectif abordé dans cette Politique).</p></li>
                    <li><p>Le droit d'accéder à vos informations personnelles et d'obtenir
                        des détails supplémentaires sur la manière dont nous les utilisons, les
                        communiquons, les conservons et les détruisons, le cas échéant.</p></li>
                    <li><p>Dans certaines situations, le droit de vous opposer, de
                        restreindre ou de retirer votre consentement en nous envoyant un préavis
                        raisonnable à l'adresse e-mail indiquée à la fin de cette
                        Politique.</p></li>
                    <li><p>Le droit de faire corriger vos informations personnelles si elles
                        sont inexactes ou incomplètes, ainsi que le droit de les
                        compléter.</p></li>
                    <li><p>Le droit de faire supprimer vos informations personnelles qui
                        sont obsolètes ou dont les objectifs de collecte sont atteints, sous
                        réserve de nos obligations légales.</p></li>
                    <li><p>Le droit d'être informé en cas d'incident de confidentialité lié
                        à vos informations personnelles et susceptible de causer un préjudice
                        sérieux.</p></li>
                </ol>
                <blockquote>
                    <p>Pour exercer ces droits, veuillez nous contacter par e-mail à
                        l'adresse indiquée à la fin de cette Politique. Nous répondrons à votre
                        demande dans un délai de 30 jours suivant sa réception, sauf si la loi
                        prévoit une extension de ce délai.</p>
                    <p>Sauf restrictions imposées par la loi, en cas de refus de fournir ou
                        de corriger vos informations, nous vous fournirons les raisons de ce
                        refus.</p>
                </blockquote>
                <p className="privacy-section-title">7. Témoins de connexion (Cookies)</p>
                <blockquote>
                    <p>Votre utilisation du Site Web nous permet de compiler automatiquement
                        certains renseignements, soit des témoins de connexion, qui peuvent
                        inclurent :</p>
                </blockquote>
                <ol type="a">
                    <li><p>L’adresse de protocole Internet (ou adresse IP) de votre
                        ordinateur;</p></li>
                    <li><p>Votre zone de géolocalisation;</p></li>
                    <li><p>Le système d’exploitation que vous utilisez;</p></li>
                    <li><p>La langue de préférence;</p></li>
                    <li><p>L’identité de votre fournisseur de services Internet;</p></li>
                    <li><p>La date et l’heure auxquels vous avez accédé au Site
                        Web;</p></li>
                    <li><p>Le Site Web précédent que vous avez visité et qui vous a fourni
                        un lien à notre Site Web; et</p></li>
                    <li><p>Le contenu visionné et téléchargé sur notre Site Web ou le nombre
                        de visites.</p></li>
                </ol>
                <blockquote>
                    <p>Généralement, les témoins de connexion que nous utilisons sont
                        regroupés en trois catégories :</p>
                </blockquote>
                <ol type="a">
                    <li><p>Témoins de connexion techniques : Les témoins techniques sont
                        utilisés tout au long de la navigation pour faciliter l’utilisation de
                        notre Site Web. Par exemple, un témoin technique peut être utilisé pour
                        se souvenir de votre nom d’utilisateur pour faciliter votre connexion,
                        pour se souvenir de vos préférences ou des options choisies;</p></li>
                    <li><p>Témoins de connexion analytiques : Ces témoins sont anonymes et
                        ils servent à recueillir des statistiques d’utilisation de notre Site
                        Web et les préférences des utilisateurs. Ces témoins peuvent être
                        utilisés pour collecter des données sur les interactions des
                        utilisateurs avec notre Site Web, telles que les clics et les mouvements
                        de souris. Les informations recueillies peuvent être utilisées pour
                        analyser le comportement des utilisateurs et améliorer l’expérience de
                        navigation sur notre Site Web.</p></li>
                    <li><p>Témoins de connexion publicitaires : Ces témoins peuvent être
                        ajoutés par le Site Web ou par d’autres sites diffusant de la publicité
                        ou des annonces. Ces témoins recueillent de l’information de façon
                        anonyme et établissent votre profil de visiteur. Ces témoins peuvent
                        également être utilisés pour suivre l’efficacité des publicités et mieux
                        cibler les annonces en fonction du comportement de navigation précédent.
                        Des témoins de connexion seront notamment utilisés par les médias
                        sociaux suivants : LinkedIn, Facebook, Tik Tok, ainsi que par des
                        partenaires tels que Microsoft, Bing, Google, Google Analytics et
                        OpenX.</p></li>
                </ol>
                <blockquote>
                    <p>Si vous le désirez, vous pouvez configurer votre navigateur pour
                        qu’il vous avertisse lorsque vous recevez des témoins ou pour qu’il les
                        refuse. Il est possible d’effacer les témoins du disque dur de votre
                        ordinateur, de bloquer la création de témoins ou de recevoir un
                        avertissement avant qu’un témoin ne soit enregistré.</p>
                    <p>Ce faisant, votre usage de notre Site Web peut toutefois être affecté
                        et il se peut que vous n’ayez alors plus accès à toutes ses fonctions.
                        Nous vous invitons à consulter les sites Ads Setting et Network
                        Advertising Initiative ainsi que les instructions ou la section d’aide
                        de votre navigateur pour plus d’informations sur les témoins de
                        connexion.</p>
                </blockquote>
                <p className="privacy-section-title">8. Responsable de la protection des Renseignements personnels</p>
                <blockquote>
                    <p>Le Responsable de la protection des Renseignements personnels chez
                        Simdev est M. Redouane Fadadi.</p>
                    <p>Pour toute question ou demande relative à la Politique, vous pouvez
                        envoyer un courriel à l’adresse suivante :</p>
                    <p><a
                        href="mailto:confidentialite@simdev.ca">confidentialite@simdev.ca</a></p>
                </blockquote>
                <p className="privacy-section-title">9. Modification</p>
                <blockquote>
                    <p>Simdev se réserve le droit de modifier le contenu de la présente
                        Politique en tout temps. La version la plus à jour figurera sur notre
                        Site Web dans la section politique de confidentialité. Nous vous
                        recommandons de réviser cette section de notre Site Web
                        périodiquement.</p>
                    <p>La présente Politique de confidentialité a été modifiée pour la
                        dernière fois le 30 novembre 2023.</p>
                </blockquote>
            </div>
        </div>
    </div>
      );
    }
    
    export default Privacy_fr;