import image1 from "../../../Assets/images/LeVIVO/LeVIVO_Longueuil_1/02-01.jpeg"
import image2 from "../../../Assets/images/LeVIVO/LeVIVO_Longueuil_1/02-01A.jpeg"
import image3 from "../../../Assets/images/LeVIVO/LeVIVO_Longueuil_1/02-01C.jpeg"
import image4 from "../../../Assets/images/LeVIVO/LeVIVO_Longueuil_1/02-01E.jpeg"
import image5 from "../../../Assets/images/LeVIVO/LeVIVO_Longueuil_1/02-02.jpeg"
import image6 from "../../../Assets/images/LeVIVO/LeVIVO_Longueuil_1/02-02A.jpeg"
import image7 from "../../../Assets/images/LeVIVO/LeVIVO_Longueuil_1/02-04.jpeg"
import image8 from "../../../Assets/images/LeVIVO/LeVIVO_Longueuil_1/02-05.jpeg"
import image9 from "../../../Assets/images/LeVIVO/LeVIVO_Longueuil_1/02-06.jpeg"
import image10 from "../../../Assets/images/LeVIVO/LeVIVO_Longueuil_1/02-07.jpeg"
import image11 from "../../../Assets/images/LeVIVO/LeVIVO_Longueuil_1/02-08.jpeg"
import image12 from "../../../Assets/images/LeVIVO/LeVIVO_Longueuil_1/02-12.jpeg"
import image13 from "../../../Assets/images/LeVIVO/LeVIVO_Longueuil_1/02-13.jpeg"
import image14 from "../../../Assets/images/LeVIVO/LeVIVO_Longueuil_1/02-14.jpeg"
import image15 from "../../../Assets/images/LeVIVO/LeVIVO_Longueuil_1/02-15.jpeg"

// NOUVELLES IMAGES EN DATE DU 2024-08-28
import image16 from "../../../Assets/images/LeVIVO/LeVIVO_Longueuil_1/02-01F.jpeg"
import image17 from "../../../Assets/images/LeVIVO/LeVIVO_Longueuil_1/02-01G.jpeg"
import image18 from "../../../Assets/images/LeVIVO/LeVIVO_Longueuil_1/02-01H.jpeg"
import image19 from "../../../Assets/images/LeVIVO/LeVIVO_Longueuil_1/02-01J.jpeg"



/* IMAGES LEVIVO LONGUEUIL 1*/

export const slidesLVLO1 = [
    {
        url: image1
    },
    {
        url: image2
    },
    {
        url: image16
    },
    {
        url: image17
    },
    {
        url: image18
    },
    {
        url: image19
    },
    {
        url: image3
    },
    {
        url: image4
    },
    {
        url: image5
    },
    {
        url: image6
    },
    {
        url: image7
    },
    {
        url: image8
    },
    {
        url: image9
    },
    {
        url: image10
    },
    {
        url: image11
    },
    {
        url: image12
    },
    {
        url: image13
    },
    {
        url: image14
    },
    {
        url: image15
    },
]

export default slidesLVLO1;