import React from 'react';
import './AngledBackgroundTextSection.scss'; 

interface VProps {
    rightChildren: React.ReactNode;
    leftChildren: React.ReactNode;
}

interface HProps {
    children: React.ReactNode;
    angledText?: string;
    isDark?: boolean;
}

// VERTICAL ANGLED BACKGROUND

export const VAngledBackgroundTextSection: React.FC<VProps> = ({ leftChildren, rightChildren }) => {
    return (
        <div className="v-angled-bg-text-container">
            <div className="v-angled-bg-text-left">
                <div className="bg-text-left-container">
                    {leftChildren}
                </div>
            </div>
            <div className="v-angled-bg-text-right">
                <div className="bg-text-right-container">
                    {rightChildren}
                </div>
            </div>
            <div className="v-angled-bg-text-rotated-block"></div>
        </div>
    )
}

// HORIZONTAL ANGLED BACKGROUND

export const HAngledBackgroundTextSection: React.FC<HProps> = ({ children, angledText=null, isDark }) => {
    return (
        <div className= {isDark ? "h-angled-bg-container bg-custom-dark" : ""} >
            <div className="h-angled-outside-div h-angled-bg-top-div">
            <p>{angledText !== null ? angledText: ""}</p>
            </div>
            <div className="h-angled-inside-div h-angled-bg-middle-div">
                {children}
            </div>
            <div id="h-angled-outside-div h-angled-bg-bottom-div"></div>
        </div>
    )   
}

