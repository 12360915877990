import image1 from '../../../Assets/images/LeVIVO/LeVIVO_Longueuil_2/05-01.jpeg'
import image2 from '../../../Assets/images/LeVIVO/LeVIVO_Longueuil_2/05-02.jpeg'
import image3 from '../../../Assets/images/LeVIVO/LeVIVO_Longueuil_2/05-03.jpeg'
import image4 from '../../../Assets/images/LeVIVO/LeVIVO_Longueuil_2/05-04.jpeg'
import image5 from '../../../Assets/images/LeVIVO/LeVIVO_Longueuil_2/05-05.jpeg'
import image6 from '../../../Assets/images/LeVIVO/LeVIVO_Longueuil_2/05-06.jpeg'
import image7 from '../../../Assets/images/LeVIVO/LeVIVO_Longueuil_2/05-06A.jpeg'
import image8 from '../../../Assets/images/LeVIVO/LeVIVO_Longueuil_2/05-06B.jpeg'
import image9 from '../../../Assets/images/LeVIVO/LeVIVO_Longueuil_2/05-07.jpeg'
import image10 from '../../../Assets/images/LeVIVO/LeVIVO_Longueuil_2/05-09.jpeg'
import image11 from '../../../Assets/images/LeVIVO/LeVIVO_Longueuil_2/05-10.jpeg'
import image12 from '../../../Assets/images/LeVIVO/LeVIVO_Longueuil_2/05-10A.jpeg'
import image13 from '../../../Assets/images/LeVIVO/LeVIVO_Longueuil_2/05-10B.jpeg'
import image14 from '../../../Assets/images/LeVIVO/LeVIVO_Longueuil_2/05-12.jpeg'
import image15 from '../../../Assets/images/LeVIVO/LeVIVO_Longueuil_2/05-13.jpeg'
import image16 from '../../../Assets/images/LeVIVO/LeVIVO_Longueuil_2/05-14.jpeg'
import image17 from '../../../Assets/images/LeVIVO/LeVIVO_Longueuil_2/05-15.jpeg'
import image18 from '../../../Assets/images/LeVIVO/LeVIVO_Longueuil_2/05-16.jpeg'

//NOUVELLES IMAGES EN DATE DU 2024-08-28
import image19 from '../../../Assets/images/LeVIVO/LeVIVO_Longueuil_2/05-17.jpeg'
import image20 from '../../../Assets/images/LeVIVO/LeVIVO_Longueuil_2/05-18.jpeg'
import image21 from '../../../Assets/images/LeVIVO/LeVIVO_Longueuil_2/05-19.jpeg'
import image22 from '../../../Assets/images/LeVIVO/LeVIVO_Longueuil_2/05-20.jpeg'

/* IMAGES LEVIVO LONGUEUIL 2*/

export const slidesLVLO2 = [
    {
        url: image20,
    },
    {
        url: image1,
    },
    {
        url: image2,
    },
    {
        url: image19,
    },
    {
        url: image21,
    },
    {
        url: image22,
    },
    {
        url: image3,
    },
    {
        url: image4,
    },
    {
        url: image5,
    },
    {
        url: image6,
    },
    {
        url: image7,
    },
    {
        url: image8,
    },
    {
        url: image9,
    },
    {
        url: image10,
    },
    {
        url: image11,
    },
    {
        url: image12,
    },
    {
        url: image13,
    },
    {
        url: image14,
    },
    {
        url: image15,
    },
    {
        url: image16,
    },
    {
        url: image17,
    },
    {
        url: image18,
    },

]

export default slidesLVLO2;